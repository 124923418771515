import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Row from '../../atoms/Row'
import Col from '../../atoms/Col'
import media from '../../../utils/media'
import Sparkles from '../../../assets/sparkles.inline.svg'
import IconClose from '../../../assets/icon-close.inline.svg'
import HumanPhone from '../../../assets/human-phone.inline.svg'

const Container = styled.div`
  width: 100vw;
  height: 80vh;
  background-color: #0049ee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;

  ${media.greaterThan('md')`
    padding: 0 300px;
    height: 70vh;
  `}
`

const BorderedContainer = styled.div`
  border-bottom: solid 2px #ff9900;
`

const CloseCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${media.lessThan('md')`
    margin-bottom: 42px;
  `}
`

const CloseButton = styled(IconClose)`
  cursor: pointer;
`

const LogoCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`

const Title = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.4px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;

  ${media.greaterThan('md')`
    font-size: 35px;
    text-align: right;
  `}
`

const Subtitle = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;

  ${media.greaterThan('md')`
    font-size: 30px;
    text-align: right;
    margin-bottom: 1.45rem;
  `}
`

const StyledHumanPhone = styled(HumanPhone)`
  width: 83px;
  height: 84px;
  display: block;

  ${media.greaterThan('sm')`
    display: none;
  `}
`

const StyledSparkles = styled(Sparkles)`
  width: 79px;
  height: 73px;
  display: block;

  ${media.greaterThan('sm')`
    display: none;
  `}
`

const TitleCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BigSparkles = styled(Sparkles)`
  display: none;
  margin-top: -60px;
  margin-left: 70px;

  ${media.greaterThan('md')`
    display: block;
  `}
`

const PhoneCol = styled(Col)`
  display: flex;
`

const BigPhone = styled(HumanPhone)`
  display: none;

  ${media.greaterThan('md')`
    display: block;
  `}
`

const HomeEndForm = ({ onClose }) => (
  <Container>
    <BorderedContainer>
      <Row>
        <CloseCol>
          <CloseButton onClick={onClose} data-testid='close-button'/>
        </CloseCol>
      </Row>
      <Row>
        <LogoCol>
          <StyledSparkles />
          <StyledHumanPhone />
        </LogoCol>
      </Row>
      <Row>
        <Col size={12} md={8}>
          <Row>
            <TitleCol>
              <BigSparkles />
              <Title dangerouslySetInnerHTML={{ __html: 'Nous avons bien<br />reçu votre demande' }} />
            </TitleCol>
          </Row>
          <Row>
            <Col>
              <Subtitle>Nos spécialistes de l’immobilier neuf vont vous contacter dans les plus brefs délais</Subtitle>
            </Col>
          </Row>
        </Col>
        <PhoneCol size={12} md={4}>
          <BigPhone />
        </PhoneCol>
      </Row>
    </BorderedContainer>
  </Container>
)

HomeEndForm.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default HomeEndForm
