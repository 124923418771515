import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import HomeEndForm from '../components/organisms/HomeEndForm'

const NousContacter = ({ location }) => {
  const [inForm, updateInForm] = useState(false)

  const answerFooterForm = async () => {
    updateInForm(true)
    trackCustomEvent({
      category: 'contact page send button',
      action: 'click',
      label: 'envoie formulaire page nous-contacter'
    })

    if (typeof window.fbq === 'function') {
      window.fbq('trackCustom', 'ContactPageSendButton')
    }
  }

  if (inForm) {
    return (
      <>
        <SEO
          location={location} />
        <Navbar location={location} />
        <HomeEndForm onClose={() => navigate('/')} />
        <Footer
          noForm={true}
          noImage={true}
          contactForm={true}
          answerForm={answerFooterForm} />
      </>
    )
  }

  return (
    <>
      <SEO location={location} />
      <Navbar location={location} />
      <Footer
        noForm={inForm}
        noImage={!inForm}
        contactForm={true}
        answerForm={answerFooterForm} />
    </>
  )
}

NousContacter.propTypes = {
  location: PropTypes.object
}

export default NousContacter
